import React from "react"
import { Link } from "gatsby"
import { Container } from "react-bootstrap"
import { Layout2022Q3 } from "@layout"
import { HeroLegal2022Q3 } from "@components/hero"
import SEO from "@components/seo"
import "@styles/legal.scss"

const TermsOfService = () => (
  <Layout2022Q3
    backgroundImage="animated"
    hero={
      <HeroLegal2022Q3
        title="Terms of Service"
        subtitle={<>Effective Date: June 1, 2024</>}
      />
    }
  >
    <SEO title="Terms of Service" />
    <Container className="policy-container body-1">
      <p>
        ATM.com, Inc. d/b/a ATM.com (referred to herein as “ATM.com”) is a US-
        based business that owns the atm.com website and mobile apps. All
        references to “we”, "us", “our”, “ATM”, or this "Website" shall be
        construed to mean ATM.com.
      </p>
      <p>
        These Terms of Service constitute a legally binding agreement that
        explain the terms and conditions that govern your access and use of this
        Website, our online and/or Mobile Application (the “Application” or
        “App”), mobile services, content, features, and software accessible
        through or in connection with this Website together with any other
        service performed for you by us in connection with this Website
        (collectively, the “Services”).
      </p>
      <p>
        ATM.com® is a registered trademark for the Services, licensed for our
        use by ATM.com, Inc.
      </p>
      <p>
        BY ACCESSING OR USING THE SERVICES, OR BY CLICKING A BUTTON OR CHECKING
        A BOX MARKED “I ACCEPT”, “I AGREE”, OR SIMILAR MARKING, YOU SIGNIFY THAT
        YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THESE TERMS (THE
        “TERMS” OR “AGREEMENT”), AND TO OUR PRIVACY POLICY LINKED AT THE BOTTOM
        OF OUR WEBSITE’S AND MOBILE APP’S PAGES. ACCEPTANCE OF THESE TERMS AND
        ACCESS TO THE SERVICES IS STRICTLY LIMITED TO INDIVIDUALS WHO ARE LEGAL
        RESIDENTS OF, AND PHYSICALLY LOCATED WITHIN, THE FIFTY (50) UNITED
        STATES AND DISTRICT OF COLUMBIA AND ARE OF AGE EIGHTEEN (18) YEARS OR
        OLDER. IF YOU ARE ATTEMPTING TO ENTER INTO THIS AGREEMENT ON BEHALF OF A
        COMPANY OR OTHER LEGAL ENTITY, ATM.COM IS UNWILLING TO ALLOW YOU TO OPEN
        AN ACCOUNT AND TO USE THE SERVICES, WHEREUPON YOU SHOULD DISCONTINUE THE
        REGISTRATION PROCESS.
      </p>
      <p>
        <strong>
          PLEASE READ THESE TERMS CAREFULLY TO ENSURE THAT YOU UNDERSTAND EACH
          PROVISION. THESE TERMS CONTAIN A MANDATORY INDIVIDUAL ARBITRATION AND
          CLASS ACTION/JURY TRIAL WAIVER PROVISION IN SECTION 20 THAT REQUIRES
          THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES,
          RATHER THAN JURY TRIALS OR CLASS ACTIONS.
        </strong>{" "}
        As used in these Terms, “You”, “you", "your", “subscriber”, and “user”
        mean the person who uses or accesses the Services. “Account" or “ATM.com
        Account” means the account you establish with us for the Services. “Bank
        Account” means your own personal checking or savings bank account and
        associated debit card this is linked to us but that is separate from
        your ATM.com Account and the Services. “Credentials” means username,
        email address, mobile phone number, PIN, password, and any log-in
        information that is used to access the Services.
      </p>
      <p>
        The Services are controlled and offered by ATM.com from the United
        States of America. ATM.com makes no representations that the Services
        are appropriate for use in other locations. Those who access or use the
        Services from other locations do so at their own risk and are
        responsible for compliance with local law.
      </p>
      <h2 id="toc">Table of Contents</h2>
      <ol className="toc">
        <li>
          <a href="#1">About ATM.com; Services</a>
        </li>
        <li>
          <a href="#2">Membership; Fees; Chargebacks; Refunds; Cancellations</a>
        </li>
        <li>
          <a href="#3">
            Your Authorization for Automated Clearing House (ACH) and Other
            Debits & Credits
          </a>
        </li>
        <li>
          <a href="#4">Electronic Fund Transfer</a>
        </li>
        <li>
          <a href="#5">Error and Dispute Resolution</a>
        </li>
        <li>
          <a href="#6">
            Collection and Use of Third-Party Financial Information
          </a>
        </li>
        <li>
          <a href="#7">
            Eligibility and Age Restrictions; Your Account and Prohibited
            Conduct
          </a>
        </li>
        <li>
          <a href="#8">
            Consent to Use Electronic Signatures and Electronic Communications
          </a>
        </li>
        <li>
          <a href="#9">Messaging</a>
        </li>
        <li>
          <a href="#10">Service Availability</a>
        </li>
        <li>
          <a href="#11">Changes to the Services and Terms; Corrections</a>
        </li>
        <li>
          <a href="#12">Legal Compliance</a>
        </li>
        <li>
          <a href="#13">Your Indemnification Obligation</a>
        </li>
        <li>
          <a href="#14">Disclaimer of Representations and Warranties</a>
        </li>
        <li>
          <a href="#15">Limitation of Liability</a>
        </li>
        <li>
          <a href="#16">Legal Notices and Special Disclaimers</a>
        </li>
        <li>
          <a href="#17">ATM.com Propriety Content</a>
        </li>
        <li>
          <a href="#18">Copyright Infringement Claims</a>
        </li>
        <li>
          <a href="#19">Application License and Device Manufacturer Terms</a>
        </li>
        <li>
          <a href="#20">Mandatory Arbitration and Class Action Waiver</a>
        </li>
        <li>
          <a href="#21">Taxes</a>
        </li>
        <li>
          <a href="#22">Assignment</a>
        </li>
        <li>
          <a href="#23">Governing Law</a>
        </li>
        <li>
          <a href="#24">Other Terms</a>
        </li>
        <li>
          <a href="#25">Privacy Policy</a>
        </li>
        <li>
          <a href="#26">California Residents</a>
        </li>
        <li>
          <a href="#27">ATM.com Contact Information</a>
        </li>
      </ol>
      <h2 id="1" className="underline">
        1. About ATM.com; Services
      </h2>
      <h3>1.1 General</h3>
      <p>
        To access and use the Services, you must register and create a user
        account. See Section 1.7 for details regarding identify verification and
        Section 7.1 for eligibility requirements.
      </p>
      <p>
        ATM.com helps you earn money as rewards (as described below in Section
        1.2, Reward Rebates, Add Data Questions, and Community Rewards;
        collectively referred to as “Rewards”) in numerous ways, without ATM.com
        selling your personal information (our limited rights to share your
        personal information are described in our{" "}
        <Link to="/privacy-policy">Privacy Policy</Link>).
      </p>
      <p>
        If you leave the ATM.com App through a link to an external source, it is
        up to you to decide what information you wish to share with third-party
        brands when calling, shopping, buying, and filling out forms or
        questionnaires. While ATM.com does not share your personal information
        with other companies, if you chose to start a survey in exchange for
        Reward Rebates, limited personal information may be shared with our
        third-party survey provider in order to better qualify you for a survey
        and maximize your earnings.
      </p>
      <p>
        Your earnings will be recorded in your ATM.com Account with a percentage
        being deposited to your investment account (see Section 1.5 below) if
        you have one. Reward Rebates may be recorded instantly but some may take
        up to 60 days based on reporting and tracking limitations with third
        party companies and networks. Regular Community Rewards will be
        scheduled once the number of users exceeds one million users.
      </p>
      <p>
        To protect our User community from fraud, your funds will be in an
        unearned state and not considered earned until your ATM.com Account
        balance reaches a threshold amount (the “Minimum Account Balance”). Once
        your earned Account balance reaches the Minimum Account Balance of
        $20.00, you can withdraw funds in excess of it. We reserve the right to
        waive or modify the threshold balance at our discretion. We keep this
        Minimum Account Balance policy to withdraw cash as a way to protect you
        and/or the User community from fraud and in turn pay Rewards faster. As
        another fraud protection measure, new users will not be allowed to
        withdraw any funds for 48 hours after they become active.
      </p>
      <p>
        All available user funds, including, but not limited to, Rewards and
        earnings, will be held in your ATM.com Account indefinitely until you
        choose to withdraw some or all of your ATM.com Account balance.
      </p>
      <p>
        To collect the final funds when an account is closed, there is a delay
        of 60 days before the transfer to your Account is commenced and the
        actual transfer may then take a few business days. The 60-day delay is
        to protect our User community from fraud. Users can view their Available
        Balance by clicking on the Wallet icon in the App and their Withdrawable
        Balance will be displayed.
      </p>
      <p>
        If you have requested to close your account and you have an outstanding
        Instant Cash Advance, please refer to Section 1.4(H).
      </p>
      <p>
        We will generally transfer the requested funds from your ATM.com Account
        to your personal bank account that you have linked to your ATM.com
        Account (together with that bank account’s associated debit card, your
        “Bank Account”) within four to six banking business days of when we
        receive your request. It is important for you to know the amount of
        available funds in your ATM.com Account before instructing us to
        transfer funds from your ATM.com Account to your Bank Account. If you do
        not have sufficient available funds in your ATM.com Account to cover the
        amount of the requested transfer, your request for the transfer will be
        declined.
      </p>
      <p>
        We may also transfer funds from your ATM.com Account to your Bank
        Account without notice to you upon the closure of your ATM.com Account
        as described below and at any time, including if required by applicable
        law, or if we, in our sole discretion, suspect the Services are being
        used for illicit purposes or otherwise in violation of these Terms.
      </p>
      <p>
        ATM.COM DOES NOT IN ANY WAY WARRANT OR GUARANTEE THE SUCCESS OF ANY
        ACTION WHICH YOU TAKE IN RELIANCE ON THE INFORMATION PROVIDED AT THIS
        WEBSITE OR THE APP. WE DO NOT WARRANT THAT YOU WILL MAKE ANY MONEY OR
        THAT YOU WILL NOT LOSE MONEY. ATM.COM IS NOT A FINANCIAL ADVISER, AND
        THE SERVICES ARE NOT INTENDED TO PROVIDE FINANCIAL ADVICE. WE ARE NOT A
        BROKER DEALER, WE ARE NOT AN INVESTMENT ADVISOR, WE DO NOT PURPORT TO
        AND DO NOT, IN ANY FASHION, PROVIDE INVESTMENT ADVICE, TAX, ACCOUNTING,
        ACTUARIAL, RECORDKEEPING, LEGAL, BROKER/DEALER OR ANY RELATED SERVICES
        OR ADVICE. WE ARE NOT RESPONSIBLE FOR ENSURING YOUR BANK ACCOUNT HAS
        SUFFICIENT FUNDS FOR YOUR NEEDS, PURPOSES, OR TRANSACTIONS. WE DO NOT
        MAKE ANY REPRESENTATIONS, WARRANTIES, OR GUARANTEES OF ANY KIND THAT THE
        SERVICES ARE APPROPRIATE FOR YOU. BEFORE USING THE SERVICES, YOU SHOULD
        CONSIDER OBTAINING ADDITIONAL INFORMATION AND ADVICE FROM A FINANCIAL
        ADVISER.
      </p>
      <h3>1.2 Basic Services</h3>
      <p>ATM.com offers the following Basic Services to users:</p>
      <p>
        <span className="underline">Reward Rebates:</span> ATM.com pays our
        users Reward Rebates as compensation for actions leading to ATM.com
        receiving revenue from the advertising brands we partner with. Actions
        can include things like placing calls with a minimum duration, receiving
        a quote, going to third-party websites to shop, buying, ordering
        services, filling out lead forms, playing games, or responding to
        surveys. Reward Rebates are paid to you directly from ATM.com. The
        companies themselves are not paying you, but instead, ATM.com derives
        advertising revenue from the companies and brands that you successfully
        interact with via the App. A portion of ATM.com’s advertising revenue is
        used to pay you for your completed actions.
      </p>
      <p>
        <span className="underline">Data Rewards:</span> Another way to earn
        money with our Services is by answering Data Vault Questions. Each day
        that you log into the App, you will have the opportunity to answer a
        number of questions. For each question that you answer, you will earn a
        small amount of money. Your answers are never shared with third parties;
        they are used exclusively by ATM.com to improve your user experience.
        See our Privacy Policy for more information on how we protect your data.
      </p>
      <p>
        <span className="underline">Community Rewards:</span> ATM.com users can
        earn passive income through Community Rewards, whereby the aggregate
        survey question results and community insights from our users,
        potentially millions, can create value for both our User community and
        medical and/or market research. These anonymized products do not
        disclose any personally identifiable information. We will de-identify
        and aggregate information collected through the Services from our users
        so that this information can no longer be linked by third parties (such
        as brands) to you or your device.
      </p>
      <h3>1.3 ATM Personal Finance</h3>
      <p>
        <span className="underline">A. What is ATM Personal Finance?</span>
        <br />
        ATM Personal Finance is a set of tools to give users insights into their
        spending to increase their financial health and plan better for today
        and the future. The tools are designed to help you:
      </p>
      <ul>
        <li>Compare/contrast current and past months’ spending</li>
        <li>Analyze spending by category and merchant</li>
        <li>
          Review data such as most frequent expenditures and largest
          transactions
        </li>
        <li>Prepare for upcoming current bills</li>
        <li>Budgeting and alerts</li>
      </ul>
      <p>
        <span className="underline">B. ATM Personal Finance Plus</span>
        <br />
        ATM Personal Finance includes all the features and benefits of ATM
        Personal Finance PLUS the following:
      </p>
      <ul>
        <li>Unlimited monitored accounts</li>
        <li>Custom categories</li>
        <li>Transaction exports</li>
      </ul>
      <p>
        ATM Personal Finance, ATM Personal Finance Plus, and ATM Instant Cash
        are collectively referred to as “ATM Personal Finance Services”.
      </p>
      <p>
        The ability to apply for and receive ATM Instant Cash advances is an
        included feature in both plans.
      </p>
      <p>
        ATM Personal Finance Services are subject to a monthly Membership Fee.
        Please refer to Section 2(B) below for details.
      </p>
      {/* Support native embedded links to #1.2 for cash advance terms */}
      <a id="1.2" name="1.2"></a>
      <h3>1.4 ATM Instant Cash&trade;</h3>
      <p>
        <span className="underline">A. What is ATM Instant Cash&trade;?</span>
        <br />
        ATM Instant Cash is a Service that provides users with a small
        non-recourse cash advance (“Advance”) against future earnings that is
        repaid upon the next direct deposit to the user’s bank account. This
        enables users to avoid overdraft fees or to pay for unexpected expenses
        without having to utilize high interest credit cards or loans. ATM
        Instant Cash is not a loan, and we have no legal or contractual claim
        against you based on a failure to repay. You are, however, expected to
        repay the Advance according to the Terms and your failure to do so may
        curtail your ability to obtain additional Advances until all Advances
        and fees have been repaid in full. Failure to pay may also affect access
        to, or the amount of, future Advances.
      </p>
      <p>
        <span className="underline">B. Eligibility and Requirements</span>
        <br />
        To be eligible to obtain ATM Instant Cash, you must:
      </p>
      <ul>
        <li>Be an individual and not a business</li>
        <li>Be a legal resident of the United States</li>
        <li>
          Be at least 18 years of age in order to form a binding contract with
          ATM.com
        </li>
        <li>Have a valid Social Security Number</li>
        <li>Not be prohibited by law from using the App or any Services</li>
      </ul>
      <p>
        You will also be required to link to a bank account that you are the
        owner and authorized user of and that:
      </p>
      <ul>
        <li>Is in a state- or federally chartered financial institution</li>
        <li>
          Is in the name of one person and not a joint account or trust account
        </li>
        <li>Has existed for more than 60 days</li>
        <li>
          Has a minimum of three (3) consistent current direct deposits from the
          same source
        </li>
        <li>Has a positive account balance</li>
        <li>Has a debit card linked to it</li>
      </ul>
      <p>
        Acceptance of some banks may be disallowed at our discretion. Deposits
        to automated teller machines, paper checks, cash transfers, or paychecks
        with irregular pay schedules do not qualify for consideration.
      </p>
      <p>
        <span className="underline">C. Application and Approval</span>
        <br />
        The Advance amount is determined by a computer analysis of the primary
        linked Bank Account using up to two years of historical income and
        expense data as well as other criteria. All Advances are provided at the
        sole discretion of ATM.com and may be increased or decreased at any time
        by us. ATM.com also reserves the right to regulate the frequency of
        Advances to you. Acceptance is subject to minimum income requirements.
      </p>
      <p>ATM.com will NOT:</p>
      <ul>
        <li>Perform a credit check</li>
        <li>Engage in debt collection activities</li>
        <li>File with credit bureaus</li>
        <li>Charge interest on the outstanding balance</li>
      </ul>
      <p>
        If your application for ATM Instant Cash is declined for any reason, you
        may re-apply after 30 days from the date of the decline notice.
      </p>
      <p>
        <span className="underline">D. Tips</span>
        <br />
        When you apply for ATM Instant Cash, you may also opt to give ATM.com an
        extra amount as a thank you for the Services it provides you (a “Tip”).
        Tips are voluntary and do not impact eligibility or Advance limits. Tips
        will be shared with various charities and non-profit organizations as
        part of our social outreach.
      </p>
      <p>
        <span className="underline">E. Disbursement and Repayment</span>
        <br />
        The deposit of ATM Instant Cash into your Bank Account will typically be
        delivered to your Bank Account within one (1) hour or less and is not
        affected by business hours or holidays.
      </p>
      <p>
        Your authorized debit from your Bank Account for repayment of the ATM
        Instant Cash Advance, fees, and tips will occur on the date of the next
        regularly scheduled direct deposit into your Bank Account. We also
        reserve the right to deduct repayment funds from your ATM.com Account if
        we are unable to process the repayment from your Bank Account.
      </p>
      <p>
        Users have the option of pre-paying the Advance and associated fees and
        tips. Pre-payment may be initiated from within the App.
      </p>
      <p>
        We may make random micro-deposits and equivalent micro-debits to verify
        your Bank Account.
      </p>
      <p>
        Credits and debits will appear on your card or bank statement with the
        descriptor in some variation of “ATM_com Instant Cash” or “ATM_com
        Subscription”.
      </p>
      <p>See Section 2 for additional information regarding payments.</p>
      <p>
        <span className="underline">F. Applying for an Advance</span>
        <br />
        Access to applying for ATM Instant Cash is included as part of your
        monthly Personal Finance Membership. You can find a full description and
        details on the Membership fee in Section 2 below.
      </p>
      <p>
        <span className="underline">G. Prohibited Use</span>
        <br />
        Users may not use their advance for depositing into their ATM investment
        account.
      </p>
      <p>
        <span className="underline">H. General Terms</span>
        <br />
        By confirming your ATM Instant Cash Advance and authorizing debits to
        your linked Bank Account, you agree to not dispute ATM.com debiting your
        Bank Account so long as the transaction corresponds to the terms you
        have confirmed.
      </p>
      <p>
        If you have requested to have your account closed and you have an
        outstanding, unsettled Instant Cash Advance, the account closure will
        not be processed until repayment of the Advance has been received.
      </p>
      <h3>1.5 Investment Program</h3>
      <p>
        <span className="underline">
          A. What is the ATM Investment Program?
        </span>
        <br />
        ATM.com works with Ant Money Advisors, LLC (“AMA”), an SEC-registered
        investment adviser, to offer investment advisory services as part of the
        Services, including the establishment of a discretionary brokerage
        account with AMA (your “Investment Account”). Upon successful completion
        of the Investment Account application, the allocated portion of your
        ATM.com Account balance will be transferred into your Investment Account
        and you will gain access to the ATM.com, Inc. Investment Program (the
        “Program”), which uses a Core & Explore approach aimed at encouraging a
        long-term “buy-and-hold” investment strategy.
      </p>
      <p>
        <span className="underline">B. The Investment Portfolios</span>
        <br />
        AMA has developed a Model Portfolio for users and participants of
        ATM.com investing for the long term. The Model Portfolio is based on the
        Core portfolio and, at the user’s option, the Explore portfolio.
      </p>
      <p>
        The Core portfolio consists of multiple exchange-traded funds (ETFs),
        ranging between approximately half and all of the value of each
        Investment Account. AMA’s algorithms conduct initial allocations and
        subsequent Rebalancing from time to time in accordance with the Terms to
        approximate the Model Portfolio.
      </p>
      <p>
        The Explore portfolio allows you to research and select up to 10
        individual stocks from a pre-selected list. Investors may, based on
        their own research and conclusions about specific companies on the
        Eligible Stock List, decide to purchase up to ten Eligible Stocks in an
        Explore Portfolio. Clients are not required under the Terms to invest in
        any Eligible Stock. To mitigate market and concentration risks, the
        Terms will permit each investor to make no more than ten purchases of
        Eligible Stocks in the Explore Portfolio. Each purchase must be of an
        Eligible Stock not currently held in the applicable Client Account and
        the amount of each purchase will be 5% (five percent) of the value of
        the applicable Client Account at the previous close. This limitation
        restricts any user’s Explore Portfolio to a maximum of 50% of the
        portfolio’s asset value.
      </p>
      <p>
        AMA reserves the right to limit or suspend activity in Investment
        Portfolio accounts at their sole discretion. AMA further reserves the
        right to restrict the frequency of purchases of Eligible Stocks in
        Explore Portfolios of particular Client Accounts on a case-by-case basis
        as it deems necessary or advisable to prevent trading that is
        inconsistent with the Program’s mission and strategy.
      </p>
      <p>
        <span className="underline">
          C. Minimum Opening Balance Requirement
        </span>
        <br />
        There is a minimum requirement of $5.00 to be received in order to open
        a new ATM Investment Account. These opening funds can come from a new
        deposit (see Section E below) or a transfer from your ATM Account.
      </p>
      <p>
        <span className="underline">D. Termination</span>
        <br />
        You may terminate your Investment Account at any time. Upon termination,
        all of the portfolio holdings in your Investment Account will be
        liquidated and returned to your Bank Account or ATM Account, at our
        discretion, and you will be removed from the ATM Investment Program.
        Should you wish to open another Investment Account in the future, you
        will have to initiate a new application. Re-entry into the ATM
        Investment Program, after closing an Investment Account, is not
        guaranteed, and applications will be available at our discretion. In the
        event of termination, there are no refunds of the Program Fee.
      </p>
      <p>
        We reserve the right to terminate your Investment Account if it falls
        below a minimum size which, in our sole opinion, is too small to manage
        effectively.
      </p>
      <p>
        <span className="underline">E. Investment Program Deposits</span>
        <br />
        You may make additional deposits into your Investment Account from your
        Bank Account. Deposits are limited to a maximum of $200 per day and a
        maximum of $2,000 per month. Deposits will be allocated in the
        Investment Account proportional to the holdings at the time. There is a
        five banking day waiting period before we will process any withdrawal
        requests supported by additional deposits. This restriction is
        implemented as a fraud prevention measure. This deposit feature may be
        suspended or removed at any time at our sole discretion.
      </p>
      <p>
        <span className="underline">F. General Disclosures</span>
        <br />
        AMA’s terms and policies can be found at{" "}
        <a href="https://www.antmoneyadvisors.com">
          www.antmoneyadvisors.com
        </a>{" "}
        or through{" "}
        <a href="https://www.adviserinfo.sec.gov">www.adviserinfo.sec.gov</a>.
        AMA facilitates trading through an SEC-registered broker-dealer that is
        a member of FINRA and SIPC. AMA reserves the right to change its
        broker-deal designee at any time and for any reason. Securities in your
        account are protected up to $500,000. For details, please see{" "}
        <a href="https://www.sipc.org">https://www.sipc.org</a>. Check the
        background of this firm on FINRA’s{" "}
        <a href="https://brokercheck.finra.org/firm/summary/165429">
          BrokerCheck
        </a>
        .
      </p>
      <h3>1.6 Banking</h3>
      <p>
        ATM.com and AMA are not depository institutions. ATM.com is not a bank;
        your ATM.com Account is a ledger account. ATM.com is not acting as a
        trustee, fiduciary or escrow with respect to your funds and ATM.com is
        not acting as a money transmitter.
      </p>
      <p>
        You must own and be the authorized user of the linked Bank Account. You
        are responsible for ensuring your Bank Account has sufficient funds for
        your needs, purposes, or transactions. By using the Services provided by
        ATM.com, you represent and warrant that you will safeguard your Bank
        Account information and will supervise and be completely responsible for
        any use of your Bank Account by you and anyone other than you.
      </p>
      <h3>1.7 Identity Verification</h3>
      <p>
        To help the government fight the funding of terrorism and money
        laundering activities, US Federal law requires all financial
        institutions and companies like ours to sometimes obtain, verify, and
        record information that identifies each person as a “customer” who opens
        an account. Identity verification is also needed to provide certain
        Services to you.
      </p>
      <p>
        As part of the verification process to eliminate fraud, when you
        register for the App or apply for ATM Instant Cash or open an Investment
        Account or take similar actions, we may ask you for your name, date of
        birth, street address, identification number (like a Social Security
        Number), debit card information, and any other information that will
        allow us to identify you as the customer associated with the bank
        account to be linked. We may also ask you to submit copies of your
        government-issued photo ID, proof of residential address, a recent bank
        statement, or similar information. All data will be by transmitted by an
        independent third-party service utilizing secure, encrypted means.
        Please review our Privacy Policy for details.
      </p>
      <p>
        Failure to provide verification of your identity and/or registered
        information when requested may result in the inability to open an
        account, the termination of your ATM.com account or limiting of
        available Services. In the event we have accepted any funds from you in
        connection with an account application or otherwise, you will be
        responsible for providing us a mailing address to which payment of any
        refund can be sent.
      </p>
      <p>
        We may share the information you have provided us, in accordance with
        our Privacy Policy, to our banking or other partners in connection with
        opening and maintaining an account for you.
      </p>
      <h2 id="2" className="underline">
        2. Membership; Fees; Chargebacks; Refunds; Cancellations
      </h2>
      <p>
        Certain Services are subject to fees under the terms and conditions that
        follow.
      </p>
      <p>
        <span className="underline">A. Basic Services</span>
        <br />
        The Basic Services are available to all users at no cost; there are no
        fees associated with accessing these features of the App.
      </p>
      <p>
        <span className="underline">
          B. Personal Finance and Personal Finance Plus - including ATM Instant
          Cash
        </span>
        <br />
        There is a $3.99 monthly Membership Fee for using ATM.com’s Personal
        Finance tools; there is an additional $4.00 monthly charge for Personal
        Finance Plus (both referred to individually and collectively as a
        “Membership”).
      </p>
      <p>
        Both Personal Finance and Personal Finance Plus include access to ATM
        Instant Cash.
      </p>
      <p>
        <span className="underline">C. ATM Instant Cash</span>
        <br />
        When you are approved for ATM Instant Cash, you will be assessed a
        Transaction Fee which allows us to maintain a connection to your Bank
        Account via third-party service provides which enable our account
        monitoring and notification services resulting in the delivery of the
        approved amount to you immediately. The Transaction Fee varies depending
        on the amount of the Advance as follows:
      </p>
      <table className="table max-width-400">
        <thead>
          <tr>
            <th>Tier</th>
            <th>Fee</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>$25.00 - $49.99</td>
            <td>$5.00</td>
          </tr>
          <tr>
            <td>$50.00 - $74.99</td>
            <td>$6.00</td>
          </tr>
          <tr>
            <td>$75.00 - $99.99</td>
            <td>$7.00</td>
          </tr>
          <tr>
            <td>$100.00 - $149.99</td>
            <td>$8.00</td>
          </tr>
          <tr>
            <td>$150.00 - $199.99</td>
            <td>$9.00</td>
          </tr>
          <tr>
            <td>$200+</td>
            <td>5.00%</td>
          </tr>
        </tbody>
      </table>
      <p>
        This fee will be debited from your Bank Account at the time of repayment
        and will be detailed before you confirm and approve and receive the
        Advance.
      </p>
      <p>
        Once an ATM Instant Cash transfer is requested, confirmed and agreed to,
        it cannot be cancelled or changed.
      </p>
      <p>
        <span className="underline">D. Investment Program</span>
        <br />
        The Investment Program is offered to users at no cost; there are
        currently no associated fees.
      </p>
      <p>
        <span className="underline">E. Inactivity</span>
        <br />
        An inactivity fee of $3 per month will be deducted from your ATM.com
        Account Balance (including your Investment Account) if your account has
        been deemed inactive (as defined in Section 7.4). If your ATM.com
        balance is insufficient to cover the full fee at the time of your
        monthly deduction, the remaining balance of your account will be
        deducted. Fee deductions will not bring an ATM.com Account below a $0.00
        Account balance. ATM.com maintains the right to waive these fees at
        their discretion.
      </p>
      <p>
        <span className="underline">F. Payment of Fees</span>
        <br />
        The initial Membership Fee will be deducted from your Bank Account when
        you enroll into the Personal Finance Program and accept the Terms. In
        the following months, the Membership Fee – a Preauthorized Electronic
        Fund Transfer (see Section 4 below) - will be deducted from your Bank
        Account on the monthly anniversary day of your enrollment or the last
        day of the calendar month whichever comes first (the “Membership Due
        Date”). (As an example, if your enrollment was on April 12, $3.99 will
        be deducted from your Bank Account on that day and $3.99 will be
        deducted from your Bank Account on the 12th day of the following months
        i.e. May 12, June 12, July 12, and so on. If the enrollment was on March
        31, the next deduction will be on April 30.) ATM.com reserves the right
        to modify or waive the Membership terms.
      </p>
      <p>
        Membership Fees are assessed on the first day of a monthly term and
        Memberships are valid and active until the first day of the next paid
        Membership period. (As an example, if $3.99 is deducted from your Bank
        Account on April 12, the Membership – and access to its features like
        ATM Instant Cash – is good through May 11.)
      </p>
      <p>
        Memberships will be deducted separate and apart from deductions for
        repayment of ATM Instant Cash and associated fees. Receipts for
        repayments to ATM.com will be sent to your email on record and will also
        be visible in the App settings.
      </p>
      <p>
        If you re-start your Membership after it has been paused (see Section G
        below), $3.99 will be immediately deducted from your Bank Account and
        that day of the month will be the new Membership Due Date for future
        Membership deductions.
      </p>
      <p>
        ATM.com avoids attempting to charge any fees or deduct a repayment if we
        detect that your account does not contain sufficient funds.
      </p>
      <p>
        We monitor your Bank Account balance and do our best to ensure you have
        sufficient funds before debiting your account for the Membership Fee,
        but the company makes no warranties that an overdraft will not occur. If
        our attempt to charge your Bank Account is denied for any reason, we
        reserve the right to make additional subsequent attempts when we detect
        a positive balance in order to collect unpaid balances from prior months
        as well as for the current period. If your Bank Account balance is not
        equal or greater than the amount due us, we may implement partial
        payments until the entire balance has been repaid.
      </p>
      <p>
        If our attempts to debit your Bank Account for repayment are
        unsuccessful because of insufficient funds to cover the repayment or an
        invalid account or some other situation, we will notify you by an in-app
        notification, a push notification, an SMS message, email, or a
        combination of those. However, after the initial rejection, we reserve
        the right to terminate your Investment Account at any time, liquidate
        the holdings, and return the proceeds to your Bank Account.
      </p>
      <p>
        Should an overdraft occur, we are not responsible for any overdraft
        fees, over-the-limit fees, insufficient fund charges, or any other bank
        fees you are charged because you fail to maintain a sufficient balance
        in your Bank Account, including any overdraft resulting from a debit
        initiated by us that had been approved by you.
      </p>
      <p>
        <span className="underline">G. Cancellation; Membership Pauses</span>
        <br />
        You may cancel or pause your Membership for Personal Finance Services at
        any time from within the App.
      </p>
      <p>
        If you pause your Membership, no additional Membership deductions will
        be taken from your Bank Account. Personal Finance Services, including
        ATM Instant Cash, will not be available to you while your Membership is
        paused.
      </p>
      <p>
        Pausing your Membership does not alter or stop the scheduled repayment
        of Instant Cash and associated fees.
      </p>
      <p>
        Once an ATM Instant Cash transfer is requested, confirmed and agreed to,
        it cannot be cancelled or changed.
      </p>
      <p>
        If you pause your membership for more than six (6) months, your
        Membership will be cancelled and, if you want to obtain ATM Instant Cash
        beyond that, you will have to re-apply.
      </p>
      <p>
        <span className="underline">H. Refunds; Chargebacks</span>
        <br />
        Transaction Fees and tips are non-refundable.
      </p>
      <p>
        Membership Fees are non-refundable. If the Membership is paused or
        cancelled before the end of a billing cycle, users will still have
        access to Personal Finance Services through the last day of the paid
        period. (As an example, if $3.99 was deducted from your Bank Account on
        April 12 and you paused the Membership on April 29, there would be no
        refund, but you would still have access to all the features through May
        11 – the end of the paid Membership period.).
      </p>
      <p>
        See Section 7.7 below for important information and more details
        regarding chargebacks.
      </p>
      <p>
        <span className="underline">I. Errors and Disputes</span>
        <br />
        ATM.com complies with the Electronic Fund Transfer Act of 1978
        (15USC§1693 et seq.) in order to protect users from fraud and account
        errors. The EFTA sets guidelines for how you can be protected from
        unauthorized electronic transactions like debit card payments and online
        transfers. For more information and details relating to disputes or
        errors, please refer to Sections 4.4, 5, and 20 below.
      </p>
      <h2 id="3" className="underline">
        3. Your Authorization for Automated Clearing House (ACH) and Other
        Debits & Credits
      </h2>
      <p>
        By agreeing to these Terms, you authorize ATM.com to electronically
        debit and credit your Bank Account (either a checking or savings
        account) via ACH, bank, or debit card transfers in order to provide the
        Services, including ATM Instant Cash. This authorization includes, if
        ever applicable, correction of erroneous debits and credits via ACH, as
        follows:
      </p>
      <ul>
        <li>
          Range of Acceptable Debit Amounts: $5 to $50 per transaction; Maximum
          $100 per Business day (as defined below)
        </li>
        <li>Frequency of Debits: Up to one time per Business Day</li>
        <li>
          Effective Date of Transactions: The Business Day on which any ACH
          transaction is scheduled
        </li>
      </ul>
      <p>
        You agree to indemnify and hold harmless ATM.com from and against any
        loss incurred as a result of its withdrawal of a preauthorized debit
        transaction from your Bank Account if any of the information relied upon
        in your request to stop payment is incorrect or incomplete.
      </p>
      <p>
        You warrant and represent to ATM.com that you have the right to
        authorize us to charge and credit your Bank Account for payments due to
        us under this Agreement.
      </p>
      <p>
        You acknowledge that the electronic authorization contained in this
        Section represents your written authorization for debit or credit
        transactions as provided herein and will remain in full force and effect
        until you notify ATM.com that you wish to revoke this authorization by
        emailing <a href="mailto:support@atm.com">support@ATM.com</a>. You must
        notify ATM.com in order to cancel this authorization. You should print a
        copy of this authorization for your records. ATM.com reserves the right
        to cancel and close your account if this authorization is revoked.
      </p>
      <p>
        Revocation of ACH approval will not be processed until any and all
        transfers and pending transactions have been processed and settled. This
        includes withdrawals and deposits to your Bank Account, as well as
        unsettled ATM Instant Cash fees and repayments.
      </p>
      <p>
        In addition to any of your other representations and warranties in these
        Terms, you represent that: (a) your browser is equipped with at least
        128-bit security encryption; (b) you are capable of printing, storing,
        or otherwise saving a copy of this electronic authorization for your
        records; and (c) the ACH transactions you hereby authorize comply with
        applicable law.
      </p>
      <h2 id="4" className="underline">
        4. Electronic Fund Transfer
      </h2>
      <p>
        The following disclosures are made in accordance with the federal
        Electronic Fund Transfer Act of 1978 as amended. It is also referred to
        as the Federal Reserve Board Regulation E. Click to view the full text
        of the{" "}
        <a href="https://uscode.house.gov/view.xhtml?req=granuleid%3AUSC-prelim-title15-chapter41-subchapter6&edition=prelim">
          US Code
        </a>{" "}
        or{" "}
        <a href="https://www.federalreserve.gov/boarddocs/supmanual/cch/efta.pdf">
          Regulation E
        </a>
        .
      </p>
      <h3>4.1 Electronic Fund Transfer Definitions</h3>
      <p>
        An <u>Electronic Fund Transfer</u> (EFT) is a transaction that is
        processed by electronic means including, but not limited to, transfers
        to/from a debit card and ACH transfers, as instructed by You.
      </p>
      <p>
        A <u>Preauthorized Electronic Fund Transfer</u> is an EFT that You have
        authorized in advance to recur at substantially regular intervals, such
        as a monthly Subscription Fee.
      </p>
      <p>
        An Unauthorized <u>Electronic Fund Transfer</u> is an EFT from Your
        account initiated by a person other than You and from which You receive
        no benefit – with some exclusions.
      </p>
      <p>
        For the purpose of this Section 4, an Access Device (“Device”) is
        defined as a card, code, or other means of access to a consumer’s
        account or a combination of these used by a consumer to initiate EFTs.
        Devices include debit cards, personal identification numbers (PINs),
        telephone transfer and telephone bill payment codes, and other means to
        initiate an EFT.
      </p>
      <h3>4.2 Your Liability</h3>
      <p>
        Authorized Transfers: You are liable for all EFTs that you authorize,
        directly or indirectly.
      </p>
      <p>
        For the loss or theft of your Access Device, your liability will be as
        follows:
      </p>
      <ul>
        <li>
          If you notify us within two (2) business days after learning of the
          loss, theft, or compromise of your App login Credentials, you can lose
          no more than $50.
        </li>
        <li>
          If you tell us more than two (2) business days after learning of the
          loss, theft or compromise of your App login and we can prove that we
          could have prevented the loss had you contacted us, you could lose as
          much as $500.00.
        </li>
      </ul>
      <p>
        If your periodic account statement issued by your bank or financial
        institution shows Unauthorized Electronic Fund Transfers and you DO NOT
        tell us within sixty (60) days after the statement was delivered to you,
        you may not get back any money.
      </p>
      <p>
        Unauthorized Transfers:{" "}
        <strong>
          Contact us immediately at{" "}
          <a href="mailto:support@atm.com">support@ATM.com</a> if you believe
          that an unauthorized EFT has occurred or may occur concerning your
          Bank Account or if your App login Credentials have been lost, stolen
          or compromised.
        </strong>
      </p>
      <h3>4.3 Our Liability</h3>
      <p>
        We will provide you information about each transaction that debits or
        credits your Linked Bank Account or linked Debit Card.
      </p>
      <p>
        We will be liable for actual damages proximately caused by our error or
        failure to complete a transaction on time or in the correct amount when
        properly instructed by You in accordance with this Agreement.
      </p>
      <p>
        We will not be liable for losses or damages if: the Bank Account you
        specify as the payment source is closed or does not contain sufficient
        funds to complete the payment or the charge is rejected or returned by
        your bank or financial institution; a third party’s app or other system
        breaks down or does not work properly; the Services were not working
        properly and you knew about the problems when you started your payment;
        through no fault of ours, you do not have enough money in your Bank
        Account to make a payment; we do not complete a transaction because we
        had reason to believe the transaction is unauthorized or illegal; we
        cannot complete a payment due to fraud or attacks on our systems or the
        Services; circumstances beyond our control (such as fire or flood)
        prevent a payment, despite reasonable precautions we have taken; and
        other possible exceptions as stated elsewhere in these Terms of Service.
      </p>
      <p>
        ATM.com is not a financial planner or advisor and we make no guarantees
        or representations that our Services are appropriate for you.
      </p>
      <h3>4.4 Error Resolution for ATM Instant Cash</h3>
      <p>
        If you think your ATM Instant Cash receipt or statement is wrong, send
        an email to <a href="mailto:support@atm.com">support@ATM.com</a> as soon
        as possible. You may also contact
        <a href="mailto:support@atm.com">support@ATM.com</a> if you need more
        information about a transaction listed on a statement or receipt. We
        must hear from you within sixty (60) days after you were sent the first
        statement on which the problem or error occurred. When contacting us,
        you need to provide us with the following: Your name and the email
        address and phone number associated with your ATM Account; a clear and
        detailed description of the EFT or error you are questioning and why you
        think it is an error; and the dollar amount of suspected error.
      </p>
      <h3>4.5 Documentation</h3>
      <p>
        A history of your ATM Instant Cash Advance deposits and repayments can
        be viewed within the ATM App by navigating to the account settings page
        and clicking on “ATM Instant Cash”. You will not receive printed
        statements listing transactions; transactions will appear only on your
        Linked Bank Account statements or in the App.
      </p>
      <h2 id="5" className="underline">
        5. Error and Dispute Resolution
      </h2>
      <p>
        In the event you suspect an error or have questions about your Account,
        not including ATM Instant Cash, email us at{" "}
        <a href="mailto:support@atm.com">support@ATM.com</a>. If you believe you
        have found an error relating to ATM Instant Cash, please refer to Error
        Resolution for ATM Instant Cash in Section 4 above. If you think your
        Bank Account statement, receipt, or payment history within the Mobile
        App are wrong, or if you need more information about a transfer listed
        on the statement, receipt, or within the Mobile App, contact us as soon
        as you can. We must hear from you no later than 90 days after the
        statement or receipt was delivered to you. In your notification to us,
        you must:
      </p>
      <ul>
        <li>
          Tell us your name, your login ID, and phone number associated with
          your App user account.
        </li>
        <li>
          Explain as clearly as you can what your question is or describe the
          error or the transfer you are unsure about, and why you believe it is
          an error or why you need more information.{" "}
        </li>
        <li>Tell us the dollar amount of the suspected error.</li>
      </ul>
      <p>
        We will determine whether an error occurred within 10 business days
        after we hear from you and will correct any error promptly. If we need
        more time, however, we may take up to 45 days to investigate your
        complaint or question. If we decide to do this, we will credit you
        within 10 business days for the amount you think is in error, so that
        you will have the use of the money during the time it takes us to
        complete our investigation. If we ask you to put your complaint or
        question in writing and we do not receive it within 10 business days, we
        may not credit your account. Error investigation and resolution for new
        users may take up to twice as long; we will inform you of the results
        within three business days after completing our investigation.
      </p>
      <p>
        ALL QUESTIONS ABOUT TRANSACTIONS MADE THROUGH ATM.COM MUST BE DIRECTED
        TO ATM.COM, AND NOT TO THE BANK OR OTHER FINANCIAL INSTITUTION WHERE YOU
        HAVE YOUR LINKED BANK ACCOUNT. We are responsible for the Services and
        for resolving any errors in transactions made in conjunction with such
        Services.
      </p>
      <h2 id="6" className="underline">
        6. Collection and Use of Third-Party Financial Information
      </h2>
      <p>
        By using the Services, including ATM Instant Cash, you consent to and
        authorize ATM.com to access and collect your account transaction
        information maintained by non-affiliated third parties including, but
        not limited to, balance information, transaction dates, merchant names,
        and transaction types.
      </p>
      <p>
        And by using the Services, you grant us and the third-party service
        providers, the right, power, and authority to act on your behalf to
        access and transmit your personal and financial information from the
        relevant financial institutions. All data is transmitted and stored in
        compliance with our Privacy Policy and the privacy policies of the
        third-party service providers. You have the right to opt out at any time
        by contacting us at <a href="mailto:support@atm.com">support@ATM.com</a>
        .
      </p>
      <h2 id="7" className="underline">
        7. Eligibility and Age Restrictions; Your Account and Prohibited Conduct
      </h2>
      <h3>7.1 Eligibility and Age Restrictions</h3>
      <p>
        To register and/or use the Services you must be a minimum of 18 years of
        age and be a legal resident of, and physically located within, the fifty
        United States and District of Columbia. Use by persons under the age of
        18 is strictly prohibited. By registering and/or using the Services, you
        represent and warrant that you are at least 18 years old and that you
        have the right, authority and capacity to enter into and abide by the
        terms and conditions of this Agreement. You may not allow other persons
        to use your ATM.com Account, and you agree that you are the sole
        authorized user of your ATM.com Account.
      </p>
      <h3>7.2 Authorization to Retrieve and Use Information You Provide</h3>
      <p>
        By providing information about yourself, including your Credentials and
        other content through the Services (“Data Retrieval Information"), you
        are licensing such Data Retrieval Information to ATM.com for the purpose
        of providing the Services. You represent that you are entitled to
        provide the Data Retrieval Information to ATM.com to use for the purpose
        of providing the Services, without any obligation by ATM.com to pay any
        fees or be subject to any restrictions or limitations. You hereby
        authorize and permit ATM.com to use and store the Data Retrieval
        Information for the purpose of providing the Services, including
        configuring the Services to be compatible with third-party websites that
        enable ATM.com to provide the Services. ATM.com will store Data
        Retrieval information in encrypted form.
      </p>
      <p>
        As part of these Terms and Agreement, and solely for the purpose of
        providing the Services, you grant ATM.com a limited power of attorney,
        and appoint ATM.com as your attorney-in-fact and agent, to access
        third-party websites, use Data Retrieval Information you provide, and
        retrieve Account Information, with the full power and authority to do
        and perform each task necessary in connection with such activities as
        you could do in person.
      </p>
      <p>
        YOU ACKNOWLEDGE AND AGREE THAT WHEN ATM.COM IS ACCESSING AND RETRIEVING
        ACCOUNT INFORMATION FROM THIRD-PARTY WEBSITES, ATM.COM IS ACTING AS YOUR
        AGENT, AND NOT AS THE AGENT OF OR ON BEHALF OF THE THIRD PARTY THAT
        OPERATES THE THIRD-PARTY WEBSITE.
      </p>
      <p>
        ATM.com cannot always foresee or anticipate technical or other
        difficulties that may result in failure to obtain data or in loss of
        data. Further, ATM.com does not assume responsibility for the
        timeliness, accuracy, deletion, or non-delivery of, or failure to store,
        any data.
      </p>
      <p>
        You represent and warrant to us that you have all rights to the Data
        Retrieval Information and Account Information that you provide to us via
        the Services. You are responsible for the accuracy and completeness of
        the Data Retrieval Information and Account Information you provide
        through the Services. You, and not ATM.com, are liable in the event that
        such Data Retrieval Information and/or Account Information is not
        accurate or complete. You agree that you will indemnify, defend, and
        hold harmless ATM.com for all claims by third parties resulting from
        Data Retrieval Information and/or Account Information that you provide
        us. We reserve the right, at our own expense, to assume the exclusive
        defense and control of such disputes, and in any event, you will
        cooperate with us in asserting any available defenses.
      </p>
      <h3>7.3 Account Ownership and Responsibilities</h3>
      <p>
        Subject to your compliance with these Terms and Agreement, we grant you
        a limited, non-exclusive, non-sublicensable, non-transferable, and
        revocable right to access and use the Services only for your own
        internal, personal, or non-commercial use, and only in a manner that
        complies with all local, state, national, and foreign laws, treaties,
        regulations, and other legal requirements that apply to you or your use
        of the Services, including those relating to data security and data
        privacy. If your use of the Services is prohibited by applicable law,
        then you do not have authorization to use the Services. ATM.com is not
        responsible for unlawful uses of the ATM.com App or Services.
      </p>
      <p>
        In addition to the other requirements listed throughout these Terms, in
        order to use the Services, you: (a) must register with us on the ATM.com
        Application or Website; (b) must be located in the United States of
        America (including District of Columbia but excluding Puerto Rico, US
        Virgin Islands, and other United States territories and possessions) (c)
        have a Bank Account with a U.S. financial institution; and (d) provide
        all information requested by us, such as your name, email address,
        mobile device number, Data Retrieval Information and Account
        Information, and such other information as we may request from time to
        time (collectively, "User Information").
      </p>
      <p>
        Each ATM.com user may establish, maintain, use and control only one
        ATM.com Account. For avoidance of doubt, users may not "co-own" or
        “co-operate” ATM.com Accounts. Only one unique ATM.com account may be
        concurrently associated with one unique device at any given time. Only
        one unique device may be concurrently associated with one unique ATM.com
        account at any given time.
      </p>
      <p>
        You may only have one Bank Account linked to your ATM.com account at any
        given time. Once that Bank Account is linked to your ATM.com account,
        that Bank Account is indefinitely tied to that account only. In other
        words, it can never be linked to any other ATM.com account. You may
        unlink a Bank Account to your ATM.com Account and replace it with
        another Bank Account but you may only link three Bank Accounts over the
        lifetime of your ATM.com account.
      </p>
      <p>
        In the event ATM.com determines that a user has established, owned,
        maintained, used or controlled more than one ATM.com Account, or more
        than one user has used an ATM.com Account, in addition to any other
        rights defined by ATM.com, ATM.com reserves the right to suspend or
        terminate any or all such ATM.com Accounts and withhold or revoke any
        money earned through the Application associated with those accounts.
      </p>
      <p>
        You are solely responsible for any and all activity that occurs through
        your Account and you agree not to sell, transfer, license or assign your
        Account or any Account rights. ATM.com strictly prohibits the creation
        of accounts for anyone other than yourself.
      </p>
      <p>
        Additionally, you represent that all information you will provide or
        have provided to ATM.com upon registration and at all other times will
        be true, accurate, current and complete and you agree to update your
        User Information as necessary to maintain its validity.
      </p>
      <p>
        You must keep your User Information current with us. In order to ensure
        that we are able to provide all records, notices, statements,
        communications, and other items for all services provided to you under
        these Terms and in connection with your relationship with us, to you
        electronically, you must notify us of any change in your email address
        and your mobile device number or other text message address by updating
        your profile on the App or Website.
      </p>
      <p>
        Use of the Services may be available through a compatible mobile device
        with internet access and require certain software. You agree that you
        are solely responsible for these requirements, including any applicable
        changes, updates, and fees associated with them, as well as for
        complying with the terms of your mobile device and telecommunications
        provider.
      </p>
      <p>
        ATM.com reserves the right to block a user from accessing all or certain
        of the Services as a result of non-compliance with the terms or
        suspicious user behavior.
      </p>
      <p>
        It is your responsibility to keep your login ID and password secure and
        confidential. IF YOUR CREDENTIALS INCLUDING APP LOGIN ID OR PASSWORD ARE
        LOST OR STOLEN, NOTIFY US AT ONCE by emailing{" "}
        <a href="mailto:support@atm.com">support@ATM.com</a>.
      </p>
      <p>
        ATM.COM MAKES NO WARRANTIES OR REPRESENTATIONS OF ANY KIND, EXPRESS,
        STATUTORY, OR IMPLIED AS TO (A) THE AVAILABILITY OF TELECOMMUNICATION
        SERVICES FROM YOUR PROVIDER OR ACCESS TO THE SERVICES AT ANY TIME OR
        FROM ANY LOCATION; (B) ANY LOSS, DAMAGE, OR SECURITY INTRUSION OF THE
        TELECOMMUNICATION SERVICES; AND (C) ANY DISCLOSURE OF INFORMATION TO
        THIRD PARTIES OR FAILURE TO TRANSMIT ANY DATA, COMMUNICATIONS, OR
        SETTINGS IN CONNECTION WITH THE SERVICES.
      </p>
      <h3>7.4 Account Status and Inactivity Fees</h3>
      <p>
        An “active” ATM.com Account is defined as an account that has accessed
        the Services within the last 45 days. (Accessing the Services includes
        simply logging into the account in the app and/or utilizing any
        functions of the app.) An “inactive” ATM.com Account is defined as an
        ATM.com Account that has not accessed the Services for a consecutive
        period of 45 days. ATM.com reserves the right to deduct a monthly
        inactivity fee of $3 immediately upon the completion of 45 consecutive
        days of inactivity and every month thereafter until the user resumes
        Service activity. Accounts that have been inactive for a period of more
        than 180 days may be considered "abandoned" and may be suspended or
        terminated without warning at ATM.com's discretion. ATM.com will attempt
        to return abandoned funds to the user to the best of their abilities,
        however, it is the responsibility of the user to make sure that their
        Bank Account is active and able to accept deposits. Funds that cannot be
        returned to the user may be surrendered to the appropriate governmental
        authorities where required. Each state has varying laws as to when an
        account is subject to escheatment, and we may be required to send the
        balance in your Account to the state of your last known address. We will
        make all reasonable efforts to contact you if required by applicable law
        before transferring the remaining balance of your Account to the
        applicable state. After we surrender the funds to the state, we have no
        further liability to you for those funds and you must apply to the
        appropriate state agency to reclaim your funds.
      </p>
      <h3>7.5 Account Funds</h3>
      <p>
        All monies earned via the Services provided by ATM.com shall remain
        property of ATM.com until said funds are claimed via an active ATM.com
        Account with an approved Bank Account that has reached the Minimum
        Account Balance, or if the funds were invested into an Investment
        Account. An active ATM.com Account is defined in Section 7.4 above.
        ATM.com uses Plaid (<a href="https://plaid.com">plaid.com</a>) to
        connect users’ Bank Accounts to ATM.com. A user must connect a Bank
        Account that is supported by Plaid to form a secure connection with
        ATM.com. This is to ensure that funds can be securely transferred
        directly to a user’s Bank Account. Any Bank Account that is not
        supported by Plaid’s authentication services will not be accepted by
        ATM.com at this time, although exceptions may be made at the sole
        discretion of ATM.com. ATM.com reserves the right to block certain
        accounts that are offered by Plaid – PayPal, as an example.
      </p>
      <h3>7.6 Prohibited Conduct</h3>
      <p>
        You may not use the funds in your ATM.com Account to make purchases,
        withdraw cash, transfer funds to non-ATM.com affiliated third parties,
        or for any other purpose. We are not responsible for any third-party
        fees that may be incurred as a result of using the Services, including,
        but not limited to, third-party fees incurred as a result of maintaining
        insufficient funds in your Bank Account.
      </p>
      <p>
        You agree not to authorize any other person or entity to use your
        Credentials or mobile device to access the Services. You are solely
        responsible for the maintenance, confidentiality, and security of your
        Credentials, and other User information. Except as otherwise required by
        applicable law, you are responsible for all transactions and other
        activities authorized or performed using your Credentials or mobile
        device, whether authorized or unauthorized by you. Except as otherwise
        expressly stated in these Terms or required by applicable law, we are
        not responsible for any losses arising out of the loss or theft of your
        User Information or your mobile device or from unauthorized or
        fraudulent transactions associated with your Bank Account or your
        ATM.com Account. If you suspect or become aware of any unauthorized
        activity or access to your Credentials or mobile device, you must
        contact us immediately at{" "}
        <a href="mailto:support@atm.com">support@ATM.com</a>.
      </p>
      <p>
        The Services may permit you to submit content, send emails and other
        communications, and provide other information for publication or
        distribution to third parties (collectively, "User Content").
      </p>
      <p>
        You may not post any prohibited content including, but not limited to,
        pictures, videos, comments, or links of violent, discriminatory,
        unlawful, infringing, hateful, sexually explicit or pornographic content
        via the Services.
      </p>
      <p>
        You may not defame, stalk, bully, abuse, harass, threaten, impersonate
        or intimidate people or entities, and you must not post private or
        confidential information in User Content via the Services, including,
        without limitation, your or any other person's credit card information,
        social security or other personal identification, non-public phone
        numbers or email addresses.
      </p>
      <p>
        You may not use the Services for any illegal or unauthorized purpose.
        You agree to comply with all laws, rules and regulations (i.e. federal,
        state, local and provincial) applicable to your use of the Services and
        your User Content, including but not limited to, copyright laws.
      </p>
      <p>
        You may not change, modify, adapt or alter the Services or change,
        modify or alter another website so as to falsely imply that it is
        associated with the Services or ATM.com.
      </p>
      <p>
        You agree that you will not solicit, collect or otherwise use the login
        credentials of other ATM.com users.
      </p>
      <p>
        You must not interfere or disrupt the Services or servers or networks
        connected to the Services, including by transmitting any worms, viruses,
        spyware, malware or any other code of a destructive or disruptive
        nature. You may not inject content or code or otherwise alter or
        interfere with the way any ATM.com page is rendered or displayed in a
        user's browser or device.
      </p>
      <p>
        You understand and agree that ATM.com cannot and will not be responsible
        for the Content posted on the Services and you use the Services at your
        own risk. If you violate the letter or spirit of these Terms, or
        otherwise create risk or possible legal exposure for ATM.com, we can
        stop providing all or part of the Services to you without warning or
        notice.
      </p>
      <h3>7.7 Chargebacks</h3>
      <p>
        ATM.com reserves the right to freeze accounts that have funds that have
        been charged back. A chargeback is a forced transaction reversal
        initiated by a bank. Chargebacks occur when a bank settles a debt due to
        loss on a fraudulent or disputed transaction.
      </p>
      <p>
        ATM.com is not responsible for any third-party fees or losses that may
        be incurred as a result of using the Services, including, but not
        limited to, third-party fees incurred as a result of maintaining
        insufficient funds in your Bank Account. Please consult with your bank
        regarding their chargeback policies.
      </p>
      <p>
        Attempts to access funds that have been charged back may be suspended if
        ATM.com deems these transfers to be illegitimate or fraudulent. You
        agree that you will not attempt to evade, avoid, or circumvent any
        refund prohibitions in any manner with regards to ATM Instant Cash
        advances by disputing or otherwise seeking a “chargeback” from your
        debit card or issuing bank.
      </p>
      <h3>7.8 User Account Termination</h3>
      <p>
        We reserve the right to modify or terminate the Services or your access
        to the Services, including ATM Instant Cash, for any reason, without
        notice, at any time, and without liability to you. You can deactivate
        your ATM.com Account by contacting us at{" "}
        <a href="https://www.ATM.com/contact">https://www.ATM.com/contact</a> or
        emailing <a href="mailto:support@atm.com">support@ATM.com</a> or
        deleting your Account from within the App. If we terminate your access
        to the Services or you use the form detailed above to deactivate your
        account, your account history will no longer be accessible through your
        account.
      </p>
      <p>
        Upon termination, all licenses and other rights granted to you in these
        Terms will immediately cease. Users with terminated accounts will not be
        eligible to create additional accounts, but may, based on internal
        review, reinstate a previously terminated account. If a user is found
        with multiple accounts, access to all accounts created are subject to
        immediate termination.
      </p>
      <h2 id="8" className="underline">
        8. Consent to Use Electronic Signatures and Electronic Communications
      </h2>
      <p>
        “Communications” means any and all agreements, communications,
        disclosures, notices, records, documents and/or other items that we
        provide to you, or that you consent or agree to at our request.
      </p>
      <p>
        “Electronic Communications” means any and all Communications that we
        provide to you in electronic form, and/or any and all Communications
        that you consent or agree to in electronic form, including but not
        limited to by means of your Electronic Signature.
      </p>
      <p>
        “Electronic Media” means the internet, a website, email, messaging
        services (including SMS and/or any other form of text messaging),
        software and/or applications (including applications for mobile devices,
        hand-held devices and/or other devices), and/or any other form of
        electronic media, whether existing now and/or that may exist in the
        future.
      </p>
      <p>
        “Electronic Signature” means any means that is used to indicate Your
        consent and/or agreement by means of any Electronic Media, which may
        include, but is not limited to, clicking a button or checking a box or
        taking any other action to indicate Your consent and/or agreement on or
        in any Electronic Media.
      </p>
      <h3>8.1 Electronic Signatures and Electronic Communications.</h3>
      <p>Subject to applicable law, you consent and agree:</p>
      <ul>
        <li>
          To use Electronic Signatures to indicate that you have read and
          understand, and that You agree and consent to these Terms, our Privacy
          Policy, and/or any other conditions or agreements that we may provide
          on any form of Electronic Media;
        </li>
        <li>
          That any and all Communications that we may send or provide to you
          (even Communications that we may otherwise be required to send or
          provide you in paper form) shall be in the form of Electronic
          Communications;
        </li>
        <li>
          Each Electronic Communication will be considered to be received by you
          at the time that we first attempt to send it to you, or if posted on
          the internet or on a Site or the App within 24 hours after the time
          that it is posted;
        </li>
        <li>
          To print and save and/or electronically store a copy of all Electronic
          Communications that we send to you;
        </li>
        <li>
          To notify us of Your email address or your mobile device number or
          other text message address by contacting us;
        </li>
        <li>
          That the foregoing consents and agreements will remain in effect
          unless and until you withdraw them in accordance with this Agreement;
        </li>
        <li>
          That you represent and warrant to us that you have read and understand
          this Consent to Use Electronic Signatures and Electronic
          Communications.
        </li>
      </ul>
      <h3>8.2 Hardware and Software Requirements</h3>
      <p>
        In order to use the Services and to access and retain our Electronic
        Communications, you need to have, and you represent and warrant to us
        that you do have, and you consent and agree that at all times you will
        have, all of the following: (1) a valid email address; (2) a computer,
        laptop, tablet, smartphone or other device that is Internet-enabled and
        is connected to the internet: (3) a web browser that includes 128-bit
        encryption, such as a Current Version of Chrome, Microsoft Edge, Firefox
        or Safari, with cookies enabled; (4) a Current Version of a program that
        accurately reads and displays PDF files, such as Adobe Acrobat Reader;
        (5) a computer or device and an operating system capable of supporting
        all of the above; and (6) data storage to electronically save
        Communications or an installed printer to print them.
      </p>
      <h3>8.3 Use of a Virtual Private Network (VPN) or Proxy Server</h3>
      <p>
        A virtual private network (VPN) is a service that can encrypt and route
        certain network traffic from your device in a way that may hide your IP
        address from the websites and services you visit, including the ability
        to determine your physical location. A proxy server acts as a gateway
        between you and the internet – it’s an intermediary that separates users
        from the websites and apps they visit primarily for the purpose of data
        security, network performance, and privacy. The use of the ATM app is
        strictly limited to users in the United States. Using a VPN or proxy
        server to interact with the ATM App may result in the App blocking your
        use and access to some or all Services.
      </p>
      <h3>8.4. Withdrawal of Consent</h3>
      <p>
        You may withdraw your consent to receive Electronic Communications by
        contacting us. Such withdrawal will be effective only after we have had
        a reasonable time period to process such withdrawal and it will not
        apply to Electronic Communications provided by us to you before the
        withdrawal of your consent becomes effective. If you withdraw your
        consent, then, except as may be expressly provided otherwise in this
        Agreement, (a) we will terminate your access to, and your ability to
        use, the Service, and (b) we will close any account that you may have
        with us and will return to you any balance that you may have therein.
        Withdrawal of consent does not, however, restrict the sending of
        communications required by any court or legal jurisdiction or
        governmental regulatory agency.
      </p>
      <h3>8.5 Changes</h3>
      <p>
        We reserve the right, in our sole discretion, (a) to communicate with
        you in paper form and/or (b) to discontinue to provide you with
        Electronic Communications, and/or (c) to terminate our Agreement and
        conditions with respect to Electronic Signatures and Electronic
        Communications. Subject to any applicable law, we may notify you of any
        such discontinuance, termination by means of Electronic Communications,
        by updating this Agreement on the Site or the Services, or by delivering
        notice by email or text message, or by any other means of Electronic
        Communications that we may choose in our discretion.
      </p>
      <h3>
        8.6 Contacting Us Regarding Electronic Signatures and Electronic
        Communications
      </h3>
      <p>
        Please feel free to request information, to request a paper copy of any
        Electronic Communication that we provided to you, or ask questions
        regarding our policy on Electronic Signatures and Electronic
        Communications by contacting us.
      </p>
      <h2 id="9" className="underline">
        9. Messaging
      </h2>
      <p>
        As part of the Services, you may receive communications through the
        Services, including push notifications, in-app notices, and messages
        that ATM.com sends you (for example, via email or SMS). When signing up
        for the Services, you may receive a welcome message and instructions on
        how to stop receiving messages. By signing up for the Services and
        providing your wireless number, you confirm that you consent to
        receiving messages from ATM.com regarding information that ATM.com
        thinks may be of interest to you, which may include ATM.com using
        automated dialing technology to text (SMS) you at the wireless number
        you provided. This consent applies to all telephone numbers you provide
        to us now or in the future.
      </p>
      <p>
        You do not have to agree to receive marketing-related, autodialed or
        prerecorded SMS or texts in order to use and enjoy the App or the
        Services. If you choose to receive marketing-related prerecorded SMS or
        texts via long code, you can later opt-out of such marketing-related
        messages in several ways, including by contacting us at{" "}
        <a href="mailto:support@atm.com">support@ATM.com</a>. Please note that
        opting out of receiving any form of messaging may impact your use of the
        Services.
      </p>
      <p>
        You also understand that you are responsible for any data messaging,
        texting, and other telecom fees or charges incurred from communications
        sent by ATM.com. Message frequency varies. If you have any questions
        about your text plan or data plan, it is best to contact your wireless
        provider. Carriers are not liable for delayed or undelivered messages.
      </p>
      <p>
        You certify, warrant and represent that the telephone number you have
        provided to us is your contact number and not someone else’s. You
        represent that you are permitted to receive text messages at the
        telephone number you have provided to us. You agree to promptly alert us
        whenever you stop using a telephone number.
      </p>
      <p>
        You agree to indemnify and hold ATM.com harmless from and against any
        and all claims, liabilities, damages (actual and consequential), losses
        and expenses (including attorneys’ fees) arising from or in any way
        related to your breach of the foregoing.
      </p>
      <p>
        We may modify or terminate our SMS messaging services from time to time,
        for any reason, and without notice, including the right to terminate SMS
        messaging with or without notice, without liability to you.
      </p>
      <h2 id="10" className="underline">
        10. Service Availability
      </h2>
      <p>
        Although it is the intention of ATM.com for the Services to be available
        as much as possible, there will be occasions when the Services may be
        interrupted, including, without limitation, for scheduled maintenance or
        upgrades, for emergency repairs, or due to failure of telecommunications
        links and/or equipment.
      </p>
      <h2 id="11" className="underline">
        11. Changes to the Services and Terms; Corrections
      </h2>
      <p>
        <h3 className="d-inline">11.1</h3> ATM.com is constantly evolving to
        provide the best possible experience for all of our customers, and we
        reserve the right to change and improve the features and functionality
        of the Services at any time and at our sole discretion. This includes
        adding, modifying or removing specific features and functionality of the
        Services. Additionally, we reserve the right to suspend or stop the
        Services altogether.
      </p>
      <p>
        <h3 className="d-inline">11.2</h3> We may, from time to time, modify
        these Terms. Please check this page periodically for modifications. In
        addition, we will notify you by email or by publishing a message to your
        ATM.com Account prior to the effective date of any material modification
        to these Terms. If you do not agree to, or cannot comply with, the
        modified Terms, you must stop using the Services. The modified Terms
        will take effect after their posting on their effective date, and will
        apply on a going-forward basis, unless otherwise provided in a notice to
        you, and except as provided in the Mandatory Arbitration and Class
        Action Waiver section of these Terms. Your continued use of the Services
        after any such modification constitutes your binding acceptance of such
        changes.
      </p>
      <p>
        <h3 className="d-inline">11.3</h3> There may be information regarding
        the Services and these Terms that contain typographical errors,
        inaccuracies, or omissions, including descriptions, pricing,
        availability, and various other information. We reserve the right to
        correct any errors, inaccuracies or omissions and to change or update
        the information at any time, without prior notice.
      </p>
      <h2 id="12" className="underline">
        12. Legal Compliance
      </h2>
      <p>
        You acknowledge, consent, and agree that ATM.com may access, preserve,
        and disclose content you provide us if required to do so by law or in a
        good faith belief that such access, preservation, or disclosure is
        permitted by our Privacy Policy or reasonably necessary or appropriate
        for any of the following reasons: (1) to comply with legal process
        (e.g., a subpoena, court order, or warrant); (2) to enforce these Terms;
        (3) to respond to claims that any content violates the rights of third
        parties; (4) to protect the rights, property, or personal safety of
        ATM.com, its agents and affiliates, its users, and the public; or (5) to
        address your requests.
      </p>
      <h2 id="13" className="underline">
        13. Your Indemnification Obligation
      </h2>
      <p>
        TO THE FULLEST EXTENT PERMITTED BY LAW, YOU AGREE TO INDEMNIFY, DEFEND,
        AND HOLD ATM.COM AND ITS RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES,
        MEMBERS, SHAREHOLDERS, OR REPRESENTATIVES (AND ALL SUCCESSORS AND
        ASSIGNS OF ANY OF THE FOREGOING), HARMLESS FROM AND AGAINST ANY CLAIM,
        LOSS, OR DEMAND, INCLUDING WITHOUT LIMITATION, REASONABLE ATTORNEYS'
        FEES AND DISBURSEMENTS, MADE IN CONNECTION WITH OR ARISING OUT OF YOUR
        VIOLATION OF THESE TERMS OR OUR PRIVACY POLICY, AND/OR YOUR SUBMISSION,
        POSTING, OR TRANSMISSION OF CONTENT TO THE SERVICES. WE RESERVE THE
        RIGHT, AT OUR OWN EXPENSE, TO ASSUME THE EXCLUSIVE DEFENSE AND CONTROL
        OF SUCH DISPUTES, AND IN ANY EVENT, YOU WILL COOPERATE WITH US IN
        ASSERTING ANY AVAILABLE DEFENSES.
      </p>
      <h2 id="14" className="underline">
        14. Disclaimer of Representations and Warranties
      </h2>
      <p>
        YOU AGREE THAT YOUR USE OF THE ATM.COM SITE AND SERVICES IS AT YOUR OWN
        RISK. EFFORTS BY ATM.COM TO MODIFY THE SITE SHALL NOT BE DEEMED A WAIVER
        OF THESE LIMITATIONS. THE SITE, INCLUDING ALL CONTENT, INFORMATION OR
        SERVICES PROVIDED THROUGH, OR IN CONJUNCTION WITH, THE SITE, ARE
        PROVIDED "AS IS."
      </p>
      <p>
        WE DISCLAIM ALL WARRANTIES AND REPRESENTATIONS, EITHER EXPRESS OR
        IMPLIED, WITH RESPECT TO ATM.COM AND THE SERVICES, INCLUDING, WITHOUT
        LIMITATION, (1) ANY WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A
        PARTICULAR PURPOSE, (2) OF INFORMATIONAL CONTENT OR ACCURACY, (3) OF
        NON-INFRINGEMENT, (4) OF PERFORMANCE, (5) OF TITLE, (6) THAT THE SITE
        WILL OPERATE IN AN ERROR FREE, TIMELY, SECURE, OR UNINTERRUPTED MANNER,
        IS CURRENT AND UP TO DATE AND ACCURATELY DESCRIBES ATM.COM’S PRODUCTS
        AND SERVICES, OR IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, (7)
        THAT ANY DEFECTS OR ERRORS IN THE SITE WILL BE CORRECTED, OR (8) THAT
        THE SITE IS COMPATIBLE WITH ANY PARTICULAR HARDWARE OR SOFTWARE
        PLATFORM.
      </p>
      <h2 id="15" className="underline">
        15. Limitation of Liability
      </h2>
      <p>
        IN NO EVENT SHALL ATM.COM OR ITS OFFICERS, DIRECTORS, AGENTS, EMPLOYEES,
        REPRESENTATIVES, INTERNAL OPERATING UNITS, AFFILIATES, SUBSIDIARIES,
        SUBLICENSEES, SUCCESSORS AND ASSIGNS, INDEPENDENT CONTRACTORS, AND
        RELATED PARTIES (COLLECTIVELY, WITH ATM.COM, INC., THE “ATM ENTITIES”)
        BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOSS OF PROFITS, LOSS OF
        USE, LOSS OF DATA, INTERRUPTION OF BUSINESS, OR ANY DIRECT, INDIRECT,
        INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN ANY
        WAY CONNECTED WITH THE USE OF THE SITE OR WITH THE DELAY OR INABILITY TO
        USE SAME, OR FOR ANY BREACH OF SECURITY ASSOCIATED WITH THE TRANSMISSION
        OF SENSITIVE INFORMATION THROUGH THE SITE, OR FOR ANY INFORMATION,
        PRODUCTS, AND SERVICES OBTAINED THROUGH OR VIEWED ON THE SITE, OR
        OTHERWISE ARISING OUT OF THE USE OF SAME, WHETHER BASED ON CONTRACT,
        TORT, STRICT LIABILITY, REGULATION, COMMON LAW PRECEDENT OR OTHERWISE,
        EVEN IF ATM.COM HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES AND EVEN
        IF SUCH DAMAGES RESULT FROM ATM.COM’S NEGLIGENCE OR GROSS NEGLIGENCE. IN
        NO EVENT WILL ATM.COM TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES OR
        CAUSES OF ACTION EXCEED $500.
      </p>
      <p>
        BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
        LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE
        ABOVE LIMITATION MAY NOT APPLY TO YOU. IN SUCH STATES, LIABILITY OF ANT
        ENTITIES SHALL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY LAW.
        ADDITIONAL DISCLAIMERS APPEAR WITHIN THE BODY OF THE SITE AND ARE
        INCORPORATED HEREIN BY REFERENCE. TO THE EXTENT ANY SUCH DISCLAIMERS
        PLACE GREATER RESTRICTIONS ON YOUR USE OF THE SITE OR THE MATERIAL
        CONTAINED THEREIN, SUCH GREATER RESTRICTIONS SHALL APPLY. THIS
        LIMITATION OF LIABILITY SHALL APPLY TO THIRD PARTY CLAIMS AS WELL AS
        CLAIMS BETWEEN THE PARTIES.
      </p>
      <p>
        IF APPLICABLE LAW DOES NOT ALLOW ALL OR ANY PART OF THE ABOVE LIMITATION
        OF LIABILITY TO APPLY TO YOU, THE LIMITATIONS WILL APPLY TO YOU ONLY TO
        THE EXTENT PERMITTED BY APPLICABLE LAW. IF YOU ARE A NEW JERSEY
        RESIDENT, OR A RESIDENT OF ANOTHER STATE THAT PERMITS THE EXCLUSION OF
        THESE WARRANTIES AND LIABILITIES, THEN THE ABOVE LIMITATIONS
        SPECIFICALLY DO APPLY TO YOU.
      </p>
      <h2 id="16" className="underline">
        16. Legal Notices and Special Disclaimers
      </h2>
      <p>
        ATM.COM® is a registered trademark and ANT MONEY™ is an unregistered,
        common-law trademark of ATM.com, Inc. All other trademarks are the
        property of their respective owners. GOOGLE PLAY® and the Google Play
        logo are registered trademarks of Google, Inc. APPLE®, the APPLE logo,
        and IPHONE® are registered trademarks of Apple, Inc.
      </p>
      <p>
        ATM.COM IS NOT A BROKER DEALER, WE ARE NOT AN INVESTMENT ADVISER, AND WE
        DO NOT PURPORT TO AND DO NOT, IN ANY FASHION, PROVIDE INVESTMENT ADVICE,
        TAX, ACCOUNTING, ACTUARIAL, RECORDKEEPING, LEGAL, BROKER/DEALER OR ANY
        RELATED SERVICES OR ADVICE. NO CONTENT, MATERIALS, OR INSTRUCTIONS
        SHOULD BE UNDERSTOOD AS CONSTITUTING A RECOMMENDATION TO ENTER IN ANY
        SECURITIES TRANSACTION. WE HAVE NO ACCESS TO NON-PUBLIC INFORMATION
        ABOUT PUBLICLY TRADED COMPANIES, AND WE ARE NOT REGULATED BY THE U.S.
        SECURITIES AND EXCHANGE COMMISSION.
      </p>
      <p>
        INFORMATION PROVIDED BY ATM.COM CUSTOMER SUPPORT IS FOR INFORMATIONAL
        AND GENERAL EDUCATIONAL PURPOSES ONLY AND IS NOT INVESTMENT OR FINANCIAL
        ADVICE, OR ANY OTHER TYPE OF PROFESSIONAL ADVICE. ATM.com does not make
        any guarantees or representations that your portion of the ATM.com
        Account will grow to a specific amount or that the Services are
        appropriate for you and your financial situation.
      </p>
      <p>
        Investment advisory services are provided exclusively by Ant Money
        Advisors, LLC (“AMA”), an SEC-registered investment adviser. AMA
        PROVIDES NO ASSURANCE, REPRESENTATION OR PROMISE REGARDING FUTURE
        EARNINGS OR INCOME, OR THAT YOU WILL MAKE ANY SPECIFIC AMOUNT OF MONEY,
        OR ANY MONEY AT ALL, OR THAT YOU WILL NOT LOSE MONEY. INVESTING INVOLVES
        RISK. PAST PERFORMANCE IS NOT INDICATIVE OF FUTURE RESULTS. YOU SHOULD
        UNDERTAKE YOUR OWN DUE DILIGENCE REGARDING YOUR EVALUATION OF ANY
        SERVICES AND/OR PRODUCTS PRESENTED ON THIS WEBSITE.
      </p>
      <h2 id="17" className="underline">
        17. ATM.com Proprietary Content
      </h2>
      <p>
        We offer the Services and the features, information, materials, and
        content provided and depicted through the Services (collectively,
        "ATM.com Content") solely for your personal use for the purposes
        described therein and in these Terms. Any and all other uses are
        prohibited. You may not restrict or inhibit any other person from using
        or enjoying the Services or ATM.com Content.
      </p>
      <p>
        ATM.com Content is protected by copyright, trademark, patent, trade
        secret and other laws, and, as between you and ATM.com, ATM.com owns and
        retains all rights in the ATM.com Content and the Service, with the
        exception of content that ATM.com has licensed from third parties, which
        is owned by those third parties. You will not remove, alter or conceal
        any copyright, trademark, service mark or other proprietary rights
        notices incorporated in or accompanying the ATM.com Content. You may not
        adapt or use otherwise any name, mark or logo that is identical, or
        confusingly similar to any of our trademarks, service marks and logos.
        You must avoid any action or omission which may dilute or tarnish our
        goodwill.
      </p>
      <p>
        Unless otherwise expressly permitted in the Terms, you may not copy,
        distribute, display or perform publicly, sublicense, decompile,
        disassemble, reduce to human readable form, execute publicly, make
        available to the public, adapt, make commercial use, process, compile,
        translate, sell, lend, rent, reverse engineer, combine with other
        software, modify or create derivative works of any of the ATM.com
        Content, either by yourself or by a third party on your behalf, in any
        way or by any means, including, but not limited to electronic,
        mechanical or optical means, without prior written authorization from
        the Company.
      </p>
      <p>
        The ATM.com name and logo are registered trademarks of ATM.com, and may
        not be copied, imitated or used, in whole or in part, without the prior
        written permission of ATM.com. In addition, all page headers, custom
        graphics, button icons and scripts are service marks, trademarks and/or
        trade dress of ATM.com, and may not be copied, imitated or used, in
        whole or in part, without prior written permission from ATM.com.
      </p>
      <h2 id="18" className="underline">
        18. Copyright Infringement Claims
      </h2>
      <p>
        ATM.com respects the rights of all copyright holders and in this regard,
        ATM.com has adopted and implemented a policy that provides for the
        termination in appropriate circumstances of users and account holders
        who infringe the rights of copyright holders. If you believe that your
        work has been copied in a way that constitutes copyright infringement,
        please provide ATM.com 's Copyright Agent the following information
        required by the Online Copyright Infringement Liability Limitation Act
        of the Digital Millennium Copyright Act, 17 U.S.C. 512:
      </p>
      <ol>
        <li>
          <p>
            1. A physical or electronic signature of a person authorized to act
            on behalf of the owner of an exclusive right that is allegedly
            infringed;
          </p>
        </li>

        <li>
          Identification of the copyright work claimed to have been infringed,
          or, if multiple copyrighted works at a single online site are covered
          by a single notification, a representative list of such works at that
          site;
        </li>
        <li>
          Identification of the material that is claimed to be infringing or to
          be the subject of infringing activity and that is to be removed or
          access to which is to be disabled, and information reasonably
          sufficient to permit us to locate the material;
        </li>
        <li>
          Information reasonably sufficient to permit us to contact the
          complaining party;
        </li>
        <li>
          A statement that the complaining party has a good-faith belief that
          use of the material in the manner complained of is not authorized by
          the copyright owner, its agent, or the law; and
        </li>
        <li>
          A statement that the information in the notification is accurate, and
          under penalty of perjury, that the complaining party is authorized to
          act on behalf of the owner of an exclusive right that is allegedly
          infringed.
        </li>
        <li>
          <p>
            For copyright inquiries under the Digital Millennium Copyright Act
            please contact:
            <blockquote>
              <br />
              DMCA Copyright Agent
              <br />
              ATM.com, Inc.
              <br />
              4600 Campus Drive, Suite 107
              <br />
              Newport Beach, CA 92660
              <br />
              Email: <a href="mailto:dmca@atm.com">dmca@ATM.com</a>
            </blockquote>
          </p>
        </li>
      </ol>
      <h2 id="19" className="underline">
        19. Application License and Device Manufacturer Terms
      </h2>
      <h3>19.1 License Grant for Application</h3>
      <p>
        Subject to your compliance with these Terms, we grant you a limited
        non-exclusive, non-transferable license (i) to download and install a
        copy of our mobile App on a device that you exclusively control, and
        (ii) to run such copy of the App solely for your own personal,
        non-commercial use. We reserve all rights in and to the App not
        expressly granted to you under these Terms. You may not separate
        component parts of the App. You may not distribute the App or transfer
        the App to another person. You may not run any version of the App on a
        “jailbroken device”; i.e. a device that is enabled to install software
        other than software that the device manufacturer made available for that
        device.
      </p>
      <h3>19.2 iOS and Google Terms</h3>
      <p>
        If you have downloaded our App, you agree to promptly download and
        install any new version that we make available through the iTunes App
        Store or Google Play store, as applicable. Some new versions may contain
        updated Terms. Some new versions may contain security fixes and service
        improvements, whether or not we disclose that they do; accordingly,
        failure to promptly update your version of the App may in some cases
        expose you to increased security risks or Website malfunctions.
      </p>
      <h3>19.3 Additional iOS Terms</h3>
      <p>
        You acknowledge and agree that (i) these Terms are binding between you
        and ATM.com only, and Apple is not a party hereto, and (ii) as between
        ATM.com and Apple, it is ATM.com that is responsible for the App and the
        content thereof. You must use the iOS app only on an Apple-branded
        product that runs iOS. Your use of the App must comply with the terms of
        use applicable to the Apple source from which you obtain it (including
        the Usage Rules set forth in the Apple App Store Terms of Service). You
        acknowledge that Apple has no obligation to furnish you with any
        maintenance and support platform with respect to the App.
      </p>
      <p>
        You acknowledge that Apple is not responsible for addressing any claims
        you have or any claims of any third party relating to the App or your
        possession and use of the App, including, but not limited to, (i)
        product warranty or liability claims; (ii) any claim that the App fails
        to conform to any applicable legal or regulatory requirement; (iii)
        claims arising under consumer protection or similar legislation; or (iv)
        claims that the App infringes a third party’s intellectual property
        rights.
      </p>
      <p>
        In the event of any failure of the iOS version of the App to conform to
        any applicable warranty that has not been effectively disclaimed by
        these Terms, you may notify Apple, and Apple will refund the purchase
        price for the App (if any) to you; to the maximum extent permitted by
        applicable law, Apple will have no other warranty obligation whatsoever
        with respect to the App, and, as between Apple and ATM.com, any other
        claims, losses, liabilities, damages, costs, or expenses attributable to
        any failure to conform to any warranty will be ATM.com’s responsibility,
        but only to the extent provided by these Terms and any limitations
        provided herein. Please read the entire Terms, as other sections of
        these Terms may limit ATM.com’s liability in this regard.
      </p>
      <p>
        Apple, and Apple’s subsidiaries, are third-party beneficiaries of these
        Terms. Upon your acceptance of the Terms, Apple will have the right (and
        will be deemed to have accepted the right) to enforce the Terms against
        you as a third-party beneficiary thereof.
      </p>
      <h3>19.4 Additional Google Terms</h3>
      <p>
        You acknowledge that Google is not responsible for addressing any claims
        you have or any claims of any third party relating to the App or your
        possession and use of the App, including, but not limited to, (i)
        product warranty or liability claims; (ii) any claim that the App fails
        to conform to any applicable legal or regulatory requirement; (iii)
        claims arising under consumer protection or similar legislation; or (iv)
        claims that the App infringes a third party’s intellectual property
        rights.
      </p>
      <h2 id="20" className="underline">
        20. Mandatory Arbitration and Class Action Waiver
      </h2>
      <p>
        <strong>
          PLEASE READ THIS SECTION CAREFULLY THIS SECTION 20 INCLUDING ALL
          SUBSECTIONS 20.1 THROUGH AND INCLUDING 20.10 BELOW. SECTION 20 AFFECTS
          YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT.
        </strong>
      </p>
      <h3>20.1 Application</h3>
      <p>
        You and ATM.com agree that these Terms affect interstate commerce and
        that the Federal Arbitration Act governs the interpretation and
        enforcement of these arbitration provisions. This Section 20 (Mandatory
        Arbitration and Class Action Waiver), is intended to be interpreted
        broadly and governs any and all disputes between us including but not
        limited to claims arising out of or relating to any aspect of the
        relationship between us, whether based in contract, tort, statute,
        fraud, misrepresentation or any other legal theory; claims that arose
        before these Terms or any prior agreement (including, but not limited
        to, claims related to advertising); and claims that may arise after the
        termination of these Terms. The only disputes excluded from this broad
        prohibition are the litigation of certain intellectual property and
        small court claims, as provided below.
      </p>
      <h3>20.2 Initial Dispute Resolution</h3>
      <p>
        Most disputes can be resolved without resorting to arbitration. If you
        have any dispute with us, you agree that before taking any formal
        action, you will contact us at{" "}
        <a href="mailto:support@atm.com">support@ATM.com</a> and provide a
        brief, written description of the dispute and your contact information
        (including your email address and mobile phone number, if your dispute
        relates to an account). Except for intellectual property and small
        claims court claims, the parties agree to use their best efforts to
        settle any dispute, claim, question, or disagreement directly through
        consultation with ATM.com, and good faith negotiations shall be a
        condition to either party initiating a lawsuit or arbitration.
      </p>
      <h3>20.3 Binding Arbitration</h3>
      <p>
        If the parties do not reach an agreed-upon solution within a period of
        thirty (30) days from the time informal dispute resolution is initiated
        under the Initial Dispute Resolution provision above, then either party
        may initiate binding arbitration as the sole means to resolve claims,
        (except as provided in subsection 20.7 below) subject to the terms set
        forth below. Specifically, all claims arising out of or relating to
        these Terms (including the Terms’ formation, performance, and breach),
        the parties' relationship with each other, and/or your use of the
        Services shall be finally settled by binding arbitration administered by
        JAMS in accordance with the JAMS Streamlined Arbitration Procedure Rules
        for claims that do not exceed $250,000 and the JAMS Comprehensive
        Arbitration Rules and Procedures for claims exceeding $250,000 in effect
        at the time the arbitration is initiated, excluding any rules or
        procedures governing or permitting class actions.{" "}
        <strong>
          THE ARBITRATION WILL BE CONDUCTED IN IRVINE, CALIFORNIA, UNLESS YOU
          AND WE AGREE OTHERWISE. REGARDLESS OF WHERE THE ARBITRATION IS
          CONDUCTED, YOU MAY ATTEND BY VIDEO CONFERENCE CALL.
        </strong>
      </p>
      <h3>20.4 Arbitrator’s Powers</h3>
      <p>
        The arbitrator, and not any federal, state, or local court or agency,
        shall have exclusive authority to resolve all disputes arising out of or
        relating to the interpretation, applicability, enforceability, or
        formation of these Terms including but not limited to any claim that all
        or any part of these Terms is void or voidable, whether a claim is
        subject to arbitration, or the question of waiver by litigation conduct.
        The arbitrator shall be empowered to grant whatever relief would be
        available in a court under law or in equity. The arbitrator's award
        shall be written and shall be binding on the parties and may be entered
        as a judgment in any court of competent jurisdiction.
      </p>
      <h3>20.5 Filing a Demand</h3>
      <p>
        To start an arbitration, you must do the following: (a) Write a Demand
        for Arbitration that includes a description of the claim and the amount
        of damages you seek to recover (you may find a copy of a Demand for
        Arbitration at <a href="https://www.jamsadr.com/">www.jamsadr.com</a>);
        (b) Send three copies of the Demand for Arbitration, plus the
        appropriate filing fee, to JAMS, 18881 Von Karman Ave. Suite 350,
        Irvine, CA 92612; and (c) Send one copy of the Demand for Arbitration to
        us at: ATM.com, Inc., 4600 Campus Drive, Suite 107, Attn: Legal Dept.,
        Newport Beach, CA 92660.
      </p>
      <p>
        To the extent the filing fee for the arbitration exceeds the cost of
        filing a lawsuit, ATM.com will pay the additional cost. If the
        arbitrator finds the arbitration to be non-frivolous, ATM.com will pay
        the fees invoiced by JAMS, including filing fees and arbitrator and
        hearing expenses. You are responsible for your own attorney’s fees
        unless the arbitration rules and/or applicable law provide otherwise.
      </p>
      The parties understand that, absent this mandatory arbitration provision,
      they would have the right to sue in court and have a jury trial. They
      further understand that, in some instances, the costs of arbitration could
      exceed the costs of litigation and the right to discovery may be more
      limited in arbitration than in court.
      <h3>20.6 Class Action Waiver</h3>
      <p>
        The parties further agree that the arbitration shall be conducted in the
        party’s respective individual capacities only and not as a class action
        or other representative action, and the parties expressly waive their
        right to file a class action or seek relief on a class basis. YOU AND
        ATM.COM AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR
        OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN
        ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. If any court or
        arbitrator determines that the class action waiver set forth in this
        paragraph is void or unenforceable for any reason or that an arbitration
        can proceed on a class basis, then the arbitration provisions set forth
        above shall be deemed null and void in their entirety and the parties
        shall be deemed to have not agreed to arbitrate disputes.
      </p>
      <h3>
        20.7 Exception: Litigation of Intellectual Property, Unenforceable
        Arbitration Award and Small Claims Court
      </h3>
      <p>
        NOTWITHSTANDING THE PARTIES' DECISION TO RESOLVE ALL DISPUTES THROUGH
        ARBITRATION, EITHER PARTY MAY BRING ENFORCEMENT ACTIONS, VALIDITY
        DETERMINATIONS OR CLAIMS ARISING FROM OR RELATING TO THEFT, PIRACY OR
        UNAUTHORIZED USE OF INTELLECTUAL PROPERTY, OR CLAIMS IF THE ARBITRATION
        PROCEEDING ABOVE IS FOUND TO BE UNENFORCEABLE{" "}
        <strong>
          ONLY IN THE COURTS OF THE STATE OF CALIFORNIA LOCATED IN ORANGE
          COUNTY, CALIFORNIA OR THE NEAREST FEDERAL COURT LOCATED IN CALIFORNIA.
          EACH PARTY SUBMITS TO THE PERSONAL JURISDICTION AND VENUE OF SUCH
          COURTS AND WAIVES ANY OBJECTION THERETO, INCLUDING ANY OBJECTION BASED
          ON FORUM NON CONVENIENS.
        </strong>
      </p>
      <p>
        Either party may also seek relief in a small claims court for disputes
        or claims within the scope of that court's jurisdiction.
      </p>
      <h3>20.8 30-Day Right to Opt Out</h3>
      <p>
        You have the right to opt out and not be bound by the arbitration and
        class action waiver provisions set forth above by sending written notice
        of your decision to opt out to ATM.com, Inc., 4600 Campus Drive, Suite
        107, Newport Beach, CA 92660, Attn: Arbitration Opt-Out. The notice must
        be sent within thirty (30) days of your first use of the Services.
        Otherwise, you shall be bound to arbitrate disputes in accordance with
        the terms of these paragraphs. If you opt out of these arbitration
        provisions, ATM.com also will not be bound by them.
      </p>
      <h3>20.9 Changes to This Section</h3>
      <p>
        ATM.com will provide thirty (30) days' notice of any changes to this
        section by posting on the Website. Amendments will become effective
        thirty (30) days after they are posted on the Website or sent to you by
        email. Changes to this section will otherwise apply prospectively only
        to claims arising after the thirtieth (30th) day. If a court or
        arbitrator decides that this subsection on "Changes to This Section" is
        not enforceable or valid, then this subsection shall be severed from the
        section entitled Mandatory Arbitration and Class Action Waiver, and the
        court or arbitrator shall apply the first Mandatory Arbitration and
        Class Action Waiver section in existence after you began using the
        Services.
      </p>
      <h3>20.10 Survival</h3>
      <p>
        The provisions of Sections 23 (Governing Law) and 20 (Mandatory
        Arbitration and Class Action Waiver) shall survive any termination of
        your use of the Services.
      </p>
      <h2 id="21" className="underline">
        21. Taxes
      </h2>
      <p>
        You are responsible for any applicable taxes associated with income you
        generate via the Services. All payments from us to you in relation to
        the Services will be treated as inclusive of tax (when applicable) and
        will not be adjusted.
      </p>
      <p>
        In order to comply with all tax authorities, ATM.com reserves the right
        to disclose revenue information to said authorities when applicable. In
        addition, we may be required to send users a 1099-MISC form or other
        applicable tax documents. The filing of tax forms is the sole
        responsibility of you, the end user. You agree to absolve ATM.com of
        responsibility for any related filing fees you may incur (for example,
        being charged a penalty for failing to file said tax forms in a timely
        manner).
      </p>
      <h2 id="22" className="underline">
        22. Assignment
      </h2>
      <p>
        You may not transfer, assign, or delegate these Terms or your rights or
        obligations hereunder or your ATM.com Account in any way (by operation
        of law or otherwise) without our prior written consent. To the extent
        permitted by applicable law, we may transfer, assign, or delegate these
        Terms and our rights and obligations hereunder without your consent.
      </p>
      <h2 id="23" className="underline">
        23. Governing Law
      </h2>
      <p>
        These Terms of Service will be governed by and construed in accordance
        with the laws of the State of California, without giving effect to any
        choice of law or conflict of law rules or provisions, whether Federal,
        or of any other jurisdiction, which would result in the application of
        the laws of a jurisdiction other than the State of California.
      </p>
      <h2 id="24" className="underline">
        24. Other Terms
      </h2>
      <p>
        These Terms of Service and any operating rules for the Services
        established by ATM.com constitute the entire agreement of the parties
        with respect to the subject matter hereof and supersede all previous
        written or oral agreements between the parties with respect to such
        subject matter. The provisions of these Terms of Service are for the
        benefit of ATM.com, its parent, subsidiaries, other affiliates and its
        third-party content providers and licensors and each shall have the
        right to assert and enforce such provisions directly or on its own
        behalf. You are also subject to United States export controls and are
        responsible for any violations of United States embargoes or other
        federal rules and regulations restricting exports. No waiver by either
        party of any breach or default hereunder shall be deemed to be a waiver
        of any preceding or subsequent breach or default. If any part of these
        Terms is found by a court of competent jurisdiction to be invalid or
        unenforceable, it will be replaced with language reflecting the original
        purpose in a valid and enforceable manner. The enforceable sections of
        these Terms of Service will remain binding upon the parties. The section
        headings used herein are for convenience only and shall not be given any
        legal import.
      </p>
      <p>
        Neither ATM.com nor you shall be liable for damages or for delays or
        failures in performance resulting from acts or occurrences beyond their
        reasonable control, including, without limitation: fire, lightning,
        explosion, power surge or failure, water, pandemic, acts of God, war,
        terrorism, revolution, civil commotion or acts of civil or military
        authorities or public enemies: any law, order, regulation, ordinance, or
        requirement of any government or legal body or any representative of any
        such government or legal body; or labor unrest, including without
        limitation, strikes, slowdowns, picketing, or boycotts; inability to
        secure raw materials, transportation facilities, fuel or energy
        shortages, or acts or omissions of other common carriers.
      </p>
      <p>
        ATM.com does not waive any rights regarding fraudulent activity and will
        actively pursue occurrences of fraud that we detect.
      </p>
      <h2 id="25" className="underline">
        25. Privacy Policy
      </h2>
      <p>
        ATM.com maintains a <Link to="/privacy-policy">Privacy Policy</Link>{" "}
        that details how we handle, store, and protect data. We incorporate our
        Privacy Policy into these Terms of Service. Note that we reserve the
        right to update our Privacy Policy at our discretion, and that any
        changes made to our Privacy Policy are effective when the updates are
        published live on the Website or App.
      </p>
      <h2 id="26" className="underline">
        26. California Residents
      </h2>
      <p>
        ATM.com is not currently licensed by the Department of Financial
        Protection and Innovation. If the Department does decide in the future
        to license us, and/or require us to make modifications to our cash
        advance product agreement, such developments may have no impact on your
        obligations under this agreement.
      </p>
      <h2 id="27" className="underline">
        27. ATM.com Contact Information
      </h2>
      <p>
        If you have any questions, comments, or concerns regarding these Terms
        or the Services, please contact us at{" "}
        <a href="mailto:support@atm.com">support@ATM.com</a> or ATM.com, Inc.,
        4600 Campus Drive, Suite 107, Newport Beach, CA 92660. When you email
        us, it is important to use the email address associated with your
        Account. If you don’t, we will need to verify your identity by
        alternative means.
      </p>
    </Container>
  </Layout2022Q3>
)

export default TermsOfService
